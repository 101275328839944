<template>
  <div class="container p-0 mt-4">
    <div class="alert alert-success" role="alert">
      Your order is confirmed!
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirmation',

  // props: [],

  // components: {},

  data() {
    return {}
  },

  computed: {},

  methods: {},

  created() {}
}
</script>

<style lang="scss" scoped>

</style>
